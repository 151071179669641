import * as React from "react";
import Head from "next/head";

export default function SEO({
  description = "",
  title,
  image = "",
  site = { siteName: null },
}) {
  const metaDescription = description || site?.siteName;

  return (
    <Head>
      <title>{title}</title>
      <meta property="description" content={"" || metaDescription} />
      <meta property="og:title" content={title} />
      <meta property="og:image" content={image} />
      <meta property="og:description" content={"" || metaDescription} />
      <meta property="og:type" content="website" />
    </Head>
  );
}

import React from "react";
import styled, { keyframes, css } from "styled-components";

import Block from "./block";

const animationStyles = css``;
const Wrapper = styled(Block)`
  ${({ animated }) => animationStyles}
  h2 {
    font-family: var(--font-alt);
    font-size: 1.75em;
    text-align: center;
  }
  @media (max-width: 767px) {
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 0;
  }
`;
const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const Animated = styled.span`
  opacity: 0;
  animation: ${fadeIn} 0.5s;
  animation-fill-mode: forwards;
  animation-delay: ${({ index }) => index * 0.1}s;
`;

export default function HeadingBlock(props) {
  const { animated } = props;
  let { value } = props;
  if (animated) {
    value = props.value.split("");
  }
  return (
    <Wrapper {...props}>
      <h2>
        {animated ? (
          <>
            {value.map((letter, index) => (
              <Animated index={index} key={index}>
                {letter}
              </Animated>
            ))}
          </>
        ) : (
          value
        )}
      </h2>
    </Wrapper>
  );
}

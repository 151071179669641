import React from "react";
import styled from "styled-components";
import Link from "next/link";

const Wrapper = styled.a`
  margin: 0 5px;
  position: relative;
  cursor: pointer;

  img {
    transition: 0.25s;
  }

  @media (min-width: 767px) {
    &:hover {
      .subtitle {
        opacity: 1;
        transform: translateY(0);
      }
      img {
        filter: brightness(75%);
      }
    }
  }

  @media (max-width: 767px) {
    .subtitle {
      opacity: 1;
      transform: translateY(0);
    }
    img {
      filter: brightness(75%);
    }
  }
`;

const Title = styled.p`
  text-transform: uppercase;
  margin: 0;
`;

const Subtitle = styled.span`
  font-family: var(--font);
  font-style: italic;
  position: absolute;
  text-align: left;
  top: 20px;
  left: 20px;
  right: 30%;
  padding: 2px 10px;
  border-left: 1px solid white;
  font-size: small;
  color: white;
  transition: 0.25s;
  opacity: 0;
  z-index: 1;
  transform: translateY(10px);
`;

export default function Item({ subtitle, title, thumbnail, url }) {
  return (
    <Link href={url} passHref={true}>
      <Wrapper className="list-item">
        {subtitle && <Subtitle className="subtitle">{subtitle}</Subtitle>}
        {thumbnail && (
          <img
            src={thumbnail.rendition.url}
            alt={`Illustration de ${subtitle}`}
          />
        )}
        <Title>{title}</Title>
      </Wrapper>
    </Link>
  );
}

import * as React from "react";

import { useInView } from "react-intersection-observer";
import styled, { css } from "styled-components";

const invertedStyles = css`
  --background: #111;
  --color: #f1f1f1;
  background: var(--background);
  color: var(--color);
  margin-left: calc(-1 * calc(var(--content-w) / 2));
  margin-right: calc(-1 * calc(var(--content-w) / 2));
  padding: 30px calc(var(--padding-side) + var(--content-w) / 2);
`;

const Wrapper = styled.div`
  margin: 50px 0;
  ${({ noMargin }) =>
    noMargin &&
    `
    margin-bottom: 0;
    & + div {
      margin-top: 0;
    }
    @media (max-width: 767px) {
      display: none;
    }
    `}
  > img {
    width: auto;
    max-width: 80%;
    max-height: 500px;
    display: block;
    margin: 0 auto;
    opacity: 0;
    transition: 0.5s;
    &.visible {
      opacity: 1;
    }
  }
  ${({ inverted }) => inverted && invertedStyles}
  @media (max-width: 767px) {
    max-width: 100vw;
    margin: 0 calc(var(--mobile-p) / 2);
    margin-left: 0;
    margin-right: 0;
    padding: var(--mobile-p);
    > img {
      max-width: 100%;
    }
  }
  &:last-of-type {
    margin-bottom: 0;
    padding-bottom: 50px;
  }
`;

export default function Block({
  children = [],
  image = null,
  imageSrc = null,
  ...props
}) {
  const [ref, inView, entry] = useInView({
    rootMargin: "500px",
    threshold: 0,
    triggerOnce: true,
  });
  const noMargin = image && image.id === "2156";
  return (
    <Wrapper ref={ref} className="block" noMargin={noMargin} {...props}>
      {imageSrc && (
        <img
          className={inView ? "visible" : undefined}
          src={inView ? imageSrc : undefined}
          // alt={`Image d'illustration - ${image?.imageTags?.join(",")}`}
          // title={image?.imageTags?.join(", ")}
        />
      )}
      {children}
    </Wrapper>
  );
}

import React from "react";

import Block from "./block";
import styled, { css } from "styled-components";

/**
    Possible formats from the backend
   - 1-3: Image 1/3 - Texte 2/3
   - 2-3: Image 2/3 - Texte 1/3
   - 50-50: Image 50% - Texte 50%
 */

const oneThirdStyles = css`
  ${({ align }) =>
    align && align === "right"
      ? `flex-direction: row-reverse;`
      : `flex-direction: row;`}
  img {
    max-width: calc(100% / 3);
    ${({ align }) =>
      align && align === "right" ? `margin-left: 0;` : `margin-right: 0;`}
  }
  > div {
    width: calc(200% / 3);
    ${({ align }) =>
      align && align === "left" ? `padding-left: 1em;` : `padding-right: 1em;`}
    display: inline-flex;
    justify-content: center;
    flex-direction: column;
  }
`;
const twoThirdStyles = css`
  ${({ align }) =>
    align && align === "right"
      ? `flex-direction: row-reverse;`
      : `flex-direction: row;`}
  img {
    max-width: calc(200% / 3);
    ${({ align }) =>
      align && align === "right" ? `margin-left: 0;` : `margin-right: 0;`}
  }
  > div {
    width: calc(100% / 3);
    ${({ align }) =>
      align && align === "left" ? `padding-left: 1em;` : `padding-right: 1em;`}
    display: inline-flex;
    justify-content: center;
    flex-direction: column;
  }
`;
const fiftyFiftyStyles = css`
  ${({ align }) =>
    align && align === "right"
      ? `flex-direction: row-reverse;`
      : `flex-direction: row;`}
  img {
    max-width: 50%;
    ${({ align }) =>
      align && align === "right" ? `margin-left: 0;` : `margin-right: 0;`}
  }
  > div {
    width: 50%;
    ${({ align }) =>
      align && align === "left" ? `padding-left: 1em;` : `padding-right: 1em;`}
    display: inline-flex;
    justify-content: center;
    flex-direction: column;
  }
`;

const Wrapper = styled(Block)`
    display: flex;
    flex-direction: column;
    ${({ inverted }) =>
      !inverted &&
      `
    padding: 0 var(--padding-side);
    `}
    @media (max-width: 767px) {
        padding: var(--mobile-p);
    }

    img {
        display: block;
        object-fit: contain;
    }
    @media (min-width: 767px) {
        ${({ format }) => format && format === "1-3" && oneThirdStyles}
        ${({ format }) => format && format === "2-3" && twoThirdStyles}
    ${({ format }) => format && format === "50-50" && fiftyFiftyStyles}
    }
`;

export default function ParagraphBlock({
  paragraphImage: image,
  texte,
  ...props
}) {
  return (
    <Wrapper imageSrc={image ? image.rendition.url : undefined} {...props}>
      <div dangerouslySetInnerHTML={{ __html: texte }} />
    </Wrapper>
  );
}

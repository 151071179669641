import React from "react";
import styled from "styled-components";

const Title = styled.h1`
  font-size: 2.25em;
  margin-top: 2em;
  @media (max-width: 767px) {
    font-size: 1.75em;
  }
`;
const Subtitle = styled.strong`
  display: block;
  font-weight: normal;
  font-family: var(--font);
  font-size: 1.1em;
  line-height: 1.5em;
  @media (min-width: 767px) {
    margin-bottom 2em;
  }
`;

const Cover = styled.div`
  @media (min-width: 767px) {
    margin-left: calc(-1 * calc(var(--content-w) / 2));
    margin-right: calc(-1 * calc(var(--content-w) / 2));
    text-align: center;
    img {
      width: 100vw;
      max-width: calc(var(--content-w) + 700px);
      height: auto;
    }
  }
  @media (max-width: 767px) {
    display: none;
  }
`;

const Wrapper = styled.div`
  ${({ isHome }) => isHome && `text-align: center;`}
  padding: 0 var(--padding-side);
  @media (max-width: 767px) {
    padding: var(--padding-side);
    text-align: center;
  }
`;

export default function Hero({
  title = null,
  subtitle = null,
  cover = null,
  isHome = false,
}) {
  if (!title && !subtitle) return null;

  return (
    <Wrapper cover={!!cover} isHome={isHome}>
      {cover && (
        <Cover>
          <img src={cover.rendition.url} alt={"Image de couverture"} />
        </Cover>
      )}
      {title && <Title>{title}</Title>}
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
    </Wrapper>
  );
}

import React, { useContext, useEffect, useState } from "react";

import { useAudio } from "react-use";
import styled, { css } from "styled-components";
import { useInView } from "react-intersection-observer";
import Block from "./block";
import ImagesByCollection from "./images-by-collections";

// const PrevIcon = () => (
//   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
//     <path d="m14.292969 3l-6.125 6.125-1.875 1.875 1.875 1.875 6.125 6.125.707031-.707031-6.125-6.125-1.167969-1.167969 1.167969-1.167969 6.125-6.125-.707031-.707031" />
//   </svg>
// );

// const NextIcon = () => (
//   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
//     <path d="m7.707031 3l-.707031.707031 6.125 6.125 1.167969 1.167969-1.167969 1.167969-6.125 6.125.707031.707031 6.125-6.125 1.875-1.875-1.875-1.875-6.125-6.125" />
//   </svg>
// );

const invertedStyles = css`
  --offset: calc(100vw - calc(var(--content-w) / 2));
  padding: 20px calc(0.9 * var(--offset));
`;

const Wrapper = styled(Block)`
  position: relative;
  --padding-side: 0;
  @media (max-width: 767px) {
    padding: 0;
  }
  margin: 0 auto;
  @media (min-width: 767px) {
    ${(props) => (!props.inverted ? `width: 80%;` : invertedStyles)}
  }
`;

const SoundIcon = styled.span`
  cursor: pointer;
  position: absolute;
  background-image: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjMDAwMDAwIiB4bWxuczp4PSJodHRwOi8vbnMuYWRvYmUuY29tL0V4dGVuc2liaWxpdHkvMS4wLyIgeG1sbnM6aT0iaHR0cDovL25zLmFkb2JlLmNvbS9BZG9iZUlsbHVzdHJhdG9yLzEwLjAvIiB4bWxuczpncmFwaD0iaHR0cDovL25zLmFkb2JlLmNvbS9HcmFwaHMvMS4wLyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDEwMCAxMDAiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDEwMCAxMDA7IiB4bWw6c3BhY2U9InByZXNlcnZlIj48c3dpdGNoPjxmb3JlaWduT2JqZWN0IHJlcXVpcmVkRXh0ZW5zaW9ucz0iaHR0cDovL25zLmFkb2JlLmNvbS9BZG9iZUlsbHVzdHJhdG9yLzEwLjAvIiB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIj48L2ZvcmVpZ25PYmplY3Q+PGcgaTpleHRyYW5lb3VzPSJzZWxmIj48Zz48cGF0aCBkPSJNNzIuMiwzMy4yYy0yLTEuNi01LTEuNC02LjcsMC43Yy0xLjcsMi4xLTEuMyw1LjEsMC44LDYuOGMyLjcsMi4yLDQuNCw1LjYsNC40LDkuM2MwLDQtMS45LDcuNS00LjksOS43ICAgICBjLTEuOSwxLjQtMi4yLDQuMi0wLjcsNmwwLjQsMC40YzEuNiwxLjksNC42LDIuMyw2LjUsMC44YzUtMy45LDguMi0xMC4xLDguMi0xNi45QzgwLjIsNDMuMiw3Ny4xLDM3LjIsNzIuMiwzMy4yeiI+PC9wYXRoPjxwYXRoIGQ9Ik04My4xLDE5LjhjLTItMS42LTQuOS0xLjItNi42LDAuN2wwLDBjLTEuNiwyLTEuNCw1LDAuNiw2LjZDODMuNywzMi42LDg4LDQwLjgsODgsNTBjMCw5LjItNC4zLDE3LjUtMTAuOSwyMi45ICAgICBjLTIsMS42LTIuMiw0LjYtMC42LDYuNmwwLDBjMS42LDIsNC42LDIuNCw2LjYsMC44YzguOC03LjEsMTQuNS0xOCwxNC41LTMwLjJDOTcuNSwzNy44LDkxLjksMjYuOSw4My4xLDE5Ljh6Ij48L3BhdGg+PHBhdGggZD0iTTQ0LjMsMTIuNUwyMCwzMS4ySDguMWMtMy4xLDAtNS42LDIuNS01LjYsNS42djI2LjRjMCwzLjEsMi41LDUuNiw1LjYsNS42SDIwbDI0LjQsMTguN2M0LjMsMy4zLDEwLjQsMC4zLDEwLjQtNS4yVjE3LjYgICAgIEM1NC44LDEyLjMsNDguNiw5LjIsNDQuMywxMi41eiI+PC9wYXRoPjwvZz48L2c+PC9zd2l0Y2g+PC9zdmc+);
  &.muted {
    background-image: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjMDAwMDAwIiB4bWxuczp4PSJodHRwOi8vbnMuYWRvYmUuY29tL0V4dGVuc2liaWxpdHkvMS4wLyIgeG1sbnM6aT0iaHR0cDovL25zLmFkb2JlLmNvbS9BZG9iZUlsbHVzdHJhdG9yLzEwLjAvIiB4bWxuczpncmFwaD0iaHR0cDovL25zLmFkb2JlLmNvbS9HcmFwaHMvMS4wLyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDEwMCAxMDAiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDEwMCAxMDA7IiB4bWw6c3BhY2U9InByZXNlcnZlIj48c3dpdGNoPjxmb3JlaWduT2JqZWN0IHJlcXVpcmVkRXh0ZW5zaW9ucz0iaHR0cDovL25zLmFkb2JlLmNvbS9BZG9iZUlsbHVzdHJhdG9yLzEwLjAvIiB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIj48L2ZvcmVpZ25PYmplY3Q+PGcgaTpleHRyYW5lb3VzPSJzZWxmIj48Zz48cGF0aCBkPSJNNDMuMywxMy41TDE5LjUsMzEuN0g4Yy0zLDAtNS41LDIuNC01LjUsNS41djI1LjdjMCwzLDIuNCw1LjUsNS41LDUuNWgxMS42bDIzLjcsMTguMmM0LjEsMy4yLDEwLjIsMC4yLDEwLjItNVYxOC41ICAgICBDNTMuNCwxMy4yLDQ3LjQsMTAuMyw0My4zLDEzLjV6Ij48L3BhdGg+PHBhdGggZD0iTTg5LjMsNTBsNy4yLTcuMmMxLjItMS4yLDEuMi0zLjIsMC00LjVsLTMuNC0zLjRjLTEuMi0xLjItMy4yLTEuMi00LjUsMGwtNy4yLDcuMmwtNy4yLTcuMmMtMS4yLTEuMi0zLjItMS4yLTQuNSwwICAgICBsLTMuNCwzLjRjLTEuMiwxLjItMS4yLDMuMiwwLDQuNWw3LjIsNy4ybC03LjIsNy4yYy0xLjIsMS4yLTEuMiwzLjIsMCw0LjVsMy40LDMuNGMxLjIsMS4yLDMuMiwxLjIsNC41LDBsNy4yLTcuMmw3LjIsNy4yICAgICBjMS4yLDEuMiwzLjIsMS4yLDQuNSwwbDMuNC0zLjRjMS4yLTEuMiwxLjItMy4yLDAtNC41TDg5LjMsNTB6Ij48L3BhdGg+PC9nPjwvZz48L3N3aXRjaD48L3N2Zz4=);
  }

  background-size: cover;
  background-color: white;
  background-position: center;
  background-size: 80%;
  background-repeat: no-repeat;
  border-radius: 50%;
  padding: 5px;
  display: block;
  width: 30px;
  height: 30px;
  bottom: 0;
  right: var(--mobile-p);
`;

const SlideshowWrapper = styled.div`
  display: flex;
  overflow: hidden;
  position: relative;
  margin: 0 auto;
`;

const Control = styled.span`
  margin: auto;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: var(--font);
  text-transform: lowercase;
  font-size: 12px;
  svg {
    width: 25px;
  }
  span {
    margin: 0 10px;
    position: relative;
    &:after {
      content: "";
      height: 1px;
      width: 100%;
      margin: auto;
      top: 5px;
      bottom: 0;
      right: 0;
      position: absolute;
      background: var(--color);
      display: block;
      max-width: 0;
      transition: 0.25s ease-in-out;
    }
  }
  &:hover span:after {
    max-width: 100%;
  }
`;

const Prev = styled(Control)`
  right: 50%;
  left: -50px;
  span {
    margin-right: 20px;
  }
`;

const Next = styled(Control)`
  left: 50%;
  right: -50px;
  span {
    margin-left: 20px;
  }
`;

const ImageWrapper = styled.div.attrs(({ index, currentSlide, transition }) => {
  const style = {};
  if (index > currentSlide) style.opacity = 0;
  style.transform = `translateX(${-index * 100}%)`;
  style.transition = `opacity ${transition}ms ease-in-out`;
  /* dynamicStyles += `width: 100 */
  return { style };
})`
  flex-shrink: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  padding: var(--mobile-p);
  img {
    display: block;
    width: 100%;
    height: auto;
  }
`;

const Title = styled.h2`
  font-family: var(--font-alt);
  font-size: 2em;
  @media (max-width: 767px) {
    font-size: 1.25em;
    padding-top: 1em;
  }
  text-align: center;
`;

const slideshowContext = React.createContext({
  soundEnabled: false,
  currentSlide: 0,
  setCurrentSlide: null,
  duration: 2000,
  music: null,
  loop: false,
});

function Item({ rendition, duration, imageTags, ...props }) {
  if (!rendition) return null;

  const context = useContext(slideshowContext);
  // We dont load the image source if it is not close to the slide
  // we are viewing. This kind of a custom lazyloader :)
  const threshold = 1;
  const isCloseToCurrentSlide =
    context.currentSlide === props.index ||
    context.currentSlide === props.index - threshold ||
    context.currentSlide === props.index + threshold;

  return (
    <ImageWrapper key={props.index} {...context} {...props}>
      <img
        src={isCloseToCurrentSlide ? rendition.url : undefined}
        alt={`Slide du diaporama numéro ${props.index}`}
        // title={imageTags.join(", ")}
      />
    </ImageWrapper>
  );
}

function Slideshow({ children, length }) {
  const { soundEnabled, setCurrentSlide, loop, duration, currentSlide, music } =
    useContext(slideshowContext);

  const [audio, state, controls, audioRef] = useAudio({
    src: `https://ropers.s3.amazonaws.com/${music && music.file}`,
    autoPlay: false,
  });

  const [ref, inView, entry] = useInView({
    threshold: 0,
  });

  useEffect(() => {
    const timeoutID = setTimeout(() => {
      if (length - 1 !== currentSlide && inView) {
        setCurrentSlide(currentSlide + 1);
      }
      if (length - 1 === currentSlide && inView) {
        if (loop) {
          setCurrentSlide(0);
        }
      }
    }, duration || 2000);
    return () => {
      clearTimeout(timeoutID);
    };
  }, [currentSlide, inView]);

  useEffect(() => {
    if (soundEnabled) {
      controls.volume(1);
    } else {
      controls.volume(0);
    }
  }, [soundEnabled]);

  useEffect(() => {
    if (inView) {
      controls.play();
    } else {
      controls.pause();
    }
  }, [inView]);

  return (
    <SlideshowWrapper ref={ref}>
      {audio}
      {children}
    </SlideshowWrapper>
  );
}

export default function SlideshowBlock({
  collection,
  title,
  collections,
  ...props
}) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [soundEnabled, setSoundsEnabled] = useState(false);
  // const [isPlaying, setPlaying] = useState(false);
  const { Provider } = slideshowContext;

  const collectionId = collections
    .map(({ id, name }) => {
      if (name === collection) {
        return id;
      } else {
        return;
      }
    })
    .join("");

  if (!collection) return null;

  return (
    <Wrapper {...props}>
      {title && <Title>{title}</Title>}
      <Provider
        value={{
          currentSlide,
          setCurrentSlide,
          soundEnabled,
          ...props,
        }}
      >
        <ImagesByCollection
          wrapper={Slideshow}
          item={Item}
          collectionId={collectionId}
        >
          {props.music && (
            <SoundIcon
              className={soundEnabled ? undefined : "muted"}
              onClick={() => setSoundsEnabled(!soundEnabled)}
            />
          )}
        </ImagesByCollection>
      </Provider>
    </Wrapper>
  );
}

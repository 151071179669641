/**
This component main role is to bypass the fact that Gatsby
does not allow, for now, tu use variables in static query. 

So, we fetch all available images from the graphql (...) and use
a simple loop to get the one we want. 

This can be optimized of course, but for now there is no better choice.
 */
import * as React from "react";
import { useQuery } from "@apollo/client";
import { COLLECTION_QUERY } from "queries/page";

export default function ImagesByCollection({
  collectionId,
  item: Item,
  wrapper: Wrapper,
  children = null,
}) {
  const { data, loading, error } = useQuery(COLLECTION_QUERY, {
    variables: { id: collectionId },
  });

  if (loading) {
    return <>Loading</>;
  }

  if (error) {
    console.error(error);
  }

  return (
    data && (
      <Wrapper length={data.images.length}>
        {data.images
          .slice()
          .sort((a, b) =>
            a.title.localeCompare(
              b.title,
              undefined,
              { numeric: true, ignorePunctuation: true }
            )
          )
          .map((image, index) => (
            <Item key={image.id} index={index} {...image} />
          ))}
        {children}
      </Wrapper>
    )
  );
}

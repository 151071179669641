import React from "react";
import Block from "components/block";
import styled from "styled-components";

import Item from "./item";

const Wrapper = styled(Block)`
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  img {
    display: block;
  }

  @media (max-width: 767px) {
    margin-top: 0;
    flex-direction: column;
    grid-gap: 20px 0;
    align-items: center;
  }
`;

export default function ListBlock({ items, props }) {
  return (
    <Wrapper {...props}>
      {items.map(({ page }, index) => (
        <Item key={index} {...page} />
      ))}
    </Wrapper>
  );
}

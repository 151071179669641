import React from "react";
import Link from "next/link";
import styled, { css } from "styled-components";

import HeadingBlock from "./heading-block";

function generateStyles(align, format) {
  let styles = ``;
  switch (align) {
    case "left":
      styles += `margin-right: auto; `;
      break;
    case "right":
      styles += `margin-left: auto; `;
      break;
    case "center":
      styles += `margin: 0 auto; `;
      break;
  }

  if (format) {
    styles += `width: ${format}%; `;
  }
  return css`
    ${styles}
  `;
}

const Wrapper = styled.a`
  display: block;
  text-decoration: none;

  &:last-of-type {
    padding-bottom: 50px;
  }

  ${({ align, format }) => generateStyles(align, format)}

  @media (max-width: 767px) {
    margin: 0 auto;
    width: 100%;
    padding: var(--mobile-p);
  }

  .title {
    margin-bottom: 0;
    padding: 0;
  }
`;

const ImgWrapper = styled.div`
  overflow: hidden;
  img {
    transition: 0.5s ease-in-out;
  }

  &:hover img {
    transform: scale(1.05);
  }
`;

export default function QuickLinkBlock({
  text,
  page,
  quickLinkImage: image,
  externalUrl,
  ...props
}) {
  const href = page?.url;

  return externalUrl ? (
    <Wrapper
      href={externalUrl}
      title={`Lien vers ${externalUrl}`}
      rel="noreferrer noopener"
      target="_blank"
      {...props}
    >
      <HeadingBlock className="title" value={text || ""} animated={true} />
      {image && (
        <ImgWrapper>
          <img src={image.rendition.url} alt={text.toString()} />
        </ImgWrapper>
      )}
    </Wrapper>
  ) : (
    <Link href={href} passHref={true}>
      <Wrapper {...props}>
        <HeadingBlock className="title" value={text || ""} animated={true} />
        {image && (
          <ImgWrapper>
            <img src={image.rendition.url} alt={text.toString()} />
          </ImgWrapper>
        )}
      </Wrapper>
    </Link>
  );
}

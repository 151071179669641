import React from "react";
import ParagraphBlock from "./paragraph-block";
import Block from "./block";
import HeadingBlock from "./heading-block";
import ListBlock from "./list-block";
import QuickLinkBlock from "./quick-link-block";
import SlideshowBlock from "./slideshow-block";

export default function Streamfield({ streamfield, collections }) {
  if (!streamfield) return null;
  return (
    <div>
      {streamfield.map((block, index) => {
        switch (block.blockType) {
          /**
            L'ordre du switch/case correpond à l'ordre des champs du bloc
            sur le backend. 
            Dans l'idéal, faire en sorte de le conserver.
           */
          case "HeadingBlock":
            return <HeadingBlock key={block.id} {...block} />;
          case "ParagraphBlock":
            return <ParagraphBlock key={block.id} {...block} />;
          /* // case "QuoteBlock": */
          case "QuickLinkBlock":
            return <QuickLinkBlock key={block.id} {...block} />;
          case "ImageChooserBlock":
            return (
              block.image &&
              block.image.rendition && (
                <Block
                  key={block.id}
                  image={block.image}
                  imageSrc={block.image.rendition.url}
                />
              )
            );
          case "ListBlock":
            // Les galeries utilisent des ListBlock
            // les articles utilisent des ListBlock
            if (block.field === "images") {
              return block.items.map(({ image: { rendition: { url } } }) => (
                <Block key={url} imageSrc={url} />
              ));
            } else {
              return <ListBlock key={block.id} {...block} />;
            }
          case "DiaporamaBlock":
            return (
              <SlideshowBlock
                key={block.id}
                collections={collections}
                {...block}
              />
            );
          case "RichTextBlock":
            return <Block key={block.id}>{block.value}</Block>;
          case "CharBlock":
            return <Block key={block.id}>{block.value}</Block>;
          case "AnimatedHeadingBlock":
            return <HeadingBlock key={block.id} animated={true} {...block} />;
          default:
            return null;
        }
      })}
    </div>
  );
}
